import React, { useEffect, useState } from "react";
import "./proposal-submitting-frame";

import { FaCheckCircle } from "react-icons/fa";
// @ts-ignore
import { ReactComponent as UpwexIconSVG } from "../../assets/images/upwex-icon.svg";

export const ProposalSubmittingFrame = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isSynced, setIsSynced] = useState(false);

  useEffect(() => {
    const detectingSynced = setTimeout(() => {
      setIsLoading(false);
      setIsSynced(true);
      // setIsError(true);
    }, 10000);

    return () => {
      clearTimeout(detectingSynced);
    };
  }, []);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === "proposal-synced") {
        setIsLoading(false);
        setIsSynced(true);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <div className="extension-proposal-submitting">
      <UpwexIconSVG style={{ zIndex: "1", height: "24px", width: "24px" }} />

      {isLoading && !isError && (
        <div className="extension-proposal-submitting__loader">
          <div className="loader">
            <div className="loader-description">
              <div className="loader-line"></div>
            </div>
          </div>
        </div>
      )}

      {isError && !isSynced && (
        <span className="error">
          Proposal has not sync! Please reload the page.
        </span>
      )}

      {!isLoading && isSynced && (
        <>
          <span>
            Proposal has been synced with Upwex <FaCheckCircle />
          </span>
        </>
      )}
    </div>
  );
};
