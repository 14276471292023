import React from "react";
import "./news-frame";
import { Carousel } from "antd";

const containerStyle: React.CSSProperties = {
  height: "140px",
};

const slideItems = [
  {
    id: 1,
    title: "NEW VERSION",
    description:
      "We are glad to present you a new version of the extension - 3.0.2",
    image: "/logo-white.png",
    link: "https://account.upwex.io/release-notes",
    date: "7th of Jule",
    linkTitle: "Read More",
  },
  {
    id: 3,
    title: "Share Your Feedback and Earn Free AI Requests!",
    description:
      "Your feedback is pure gold and we'd love to hear your thoughts on Upwex.",
    image: "https://www.svgrepo.com/show/229799/badge-reward.svg",
    link: "https://mt-link.upwex.io/c/cl/FkXIZOqBT1EPdghv1DczZO%2FeToK+IyPfavXOTnLyopAxqTVLjG2Ws9QObbUjolGbyMYMb0obuhItTHjgWqMLG315YdhY7g0SzNf0SPCpVzh2xZQXFjjH6ZisDKSWXUUodUKtRyKwSQtvNXFdoMaoJGwzjnQqRBlo%2FiWyGEBu7QJaGa1T43s0vmJ9ERIXblhi%2Frq6OMwCMMLu3vY=--WhN1KhXFmPchvHnw--U7cGZ6h6iw+ypxcSD9p+Ug==",
    date: "1st of Jule",
    linkTitle: "Leave Feedback",
  },
  {
    id: 3,
    title: "BIG UPDATE",
    description:
      "We are glad to present you a new version of the extension - 3.0.0",
    image: "/logo-white.png",
    link: "https://account.upwex.io/release-notes",
    date: "28th of June",
    linkTitle: "Read More",
  },
];

export const NewsFrame = (): JSX.Element => {
  return (
    <div className="extension-news">
      <Carousel
        style={containerStyle}
        draggable={true}
        // autoplay={true}
        // autoplaySpeed={5000}
      >
        {slideItems.map((item) => (
          <div key={item.id}>
            <div className="extension-news__item">
              <div className="extension-news__content">
                <div className="extension-news__info">
                  <h3 className="extension-news__desc">
                    <span className="badge">{item.date}</span>
                    {item.title}
                  </h3>
                  <p className="extension-news__title">{item.description}</p>
                  <a
                    className="extension-news__link extension-btn secondary-ex-btn"
                    href={item.link}
                    target="_blank"
                  >
                    {item.linkTitle}
                  </a>
                </div>

                <div className="extension-news__image">
                  <img src={item.image} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};
